import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  HomeOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from "@ant-design/icons";

import {
  Breadcrumb,
  Menu,
  Slider,
  Segmented,
  Select,
  Button,
  Pagination,
  Divider,
  Row,
  Col,
  Input,
  Empty,
  Form,
  Spin,
  Cascader,
} from "antd";

import { font, theme } from "../ApiService/Theme";
import crud_service from "../ApiService/ApiService";
import API from "../ApiService/ApiService";
import { base_url, company_code } from "../config";
// import { useHistory } from 'react-router-dom';

import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "../Redux/slices/product";
import { styles } from "../ApiService/Data";

import { debounce } from "lodash";

const { Option } = Select;

const api = new API();
let price_Range = [];

const Shop = () => {
  // console.log("company code", company_code);
  //to get the params value
  const location = useLocation();
  // const history = useHistory();
  const params = new URLSearchParams(location.search);
  const PARAMS = params;
  const catName = params.get("catName");
  const [pageView, setPageView] = useState("grid");
  const catId = params.get("catId");
  const product_name = params.get("product_name");

  // console.log("catId", catId)

  const navigate = useNavigate();

  // console.log("catName", catName);
  // console.log("PARAMS", PARAMS)

  const dispatch = useDispatch();

  //getting categoty from redux
  const category = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);
  // console.log("category===>", category)

  // const tax = useSelector((state) => state?.tax?.data);

  const products = useSelector(
    (state) => state?.product?.product?.data[0].data
  );
  // console.log("products", products)

  const totalProducts = useSelector(
    (state) => state?.product?.product?.data[0].totalCount[0]?.total
  );

  const specification = useSelector((state) => state?.specificationSlice?.specification?.data);

  // console.log("specification--->", specification);

  const specificationDetails = useSelector(
    (state) => state?.specificationdetailsSlice?.specificationdetails?.data
  );

  // console.log("specificationDetails--->", specificationDetails);

  const SpecificationArray = [];

  specificationDetails.forEach((item) => {
    specification?.forEach((spec) => {
      if (item?.specification_id?.name === spec?.name) {
        // Check if the key already exists, if not, create an empty array
        if (!SpecificationArray[spec?.name]) {
          SpecificationArray[spec?.name] = [];
        }
        // Push the new label and value object into the array
        SpecificationArray[spec?.name].push({
          label: item?.specification_details,
          value: item?._id
        });
      }
    });
  });
  console.log("SpecificationArray", SpecificationArray)
  
  const [size, setSize] = useState(null); // State for size selection
  // const [sortBy, setSortBy] = useState('');

  const sizeOptions = [
    // Size options
    { key: "s", label: "S" },
    { key: "m", label: "M" },
    { key: "l", label: "L" },
    { key: "xl", label: "XL" },
    { key: "2xl", label: "XXL" },
    { key: "3xl", label: "XXXL" },
  ];

  const sortOptions = [
    // { key: "sort-by-position", label: "Sort by position" },
    { key: "alphabetical-a-z", label: "Alphabetical, A-Z" },
    { key: "alphabetical-z-a", label: "Alphabetical, Z-A" },
    { key: "price-high-to-low", label: "Price, High to Low" },
    { key: "price-low-to-high", label: "Price, Low to High" },
    { key: "date-new-to-old", label: "Date, new to old" },
    { key: "date-old-to-new", label: "Date, old to new" },
  ];

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    // console.log("catNameuseEffect", catName);
    // console.log("category===>useEffect", category);

    if (category && catName) {
      const matchedCategory = category.find((item) => item.label == catName);
      if (matchedCategory) {
        // console.log("Matched category value:", matchedCategory.key);
        setCategoryId(matchedCategory.key);
      } else {
        // console.log("No matching category found");
        setCategoryId("");
      }
    }
  }, [catName, category]);

  useEffect(() => {
    // Filter by size
    // console.log("i am in")
    console.log("i am in size", size);
    // console.log("i am in products", products);
    let filtered = products;

    console.log("before filter", products?.map(item => item?.specification));

     // Object to store ids by categories dynamically
  let categorizedIds = {};

  Object.keys(SpecificationArray).forEach((category) => {
    categorizedIds[category] = size?.filter((id) =>
      SpecificationArray[category].some((item) => item?.value === id),
    // console.log("SpecificationArray[category]", SpecificationArray[category])
    );
  });

  console.log("categorizedIds", categorizedIds);

  // Extract only the non-empty categories (those that have matching IDs)
  const nonEmptyCategories = Object.keys(categorizedIds).filter(
    (category) => categorizedIds[category]?.length > 0
  );

  console.log("nonEmptyCategories", nonEmptyCategories);

    // if (size) {
    //   filtered = products?.filter((product) =>
    //     product?.specification?.some(       
    //       (item) => item?.specification_details.includes(size),         
    //     )
    //   );
    // }

    // if (size?.length) {
    //   filtered = products?.filter((product) =>
    //     product?.specification?.some((item) =>
    //       size.some((id) => item?.specification_details.includes(id))
    //     )
    //   );
    // }

     // Filtering logic
     if (size?.length) {
      filtered = products?.filter((product) => {
        return product?.specification?.some((item) => {
          if (nonEmptyCategories.length > 1) {
            // console.log("i am in if && part");
  
            // AND logic across categories
            return nonEmptyCategories.every((category) => {
              // OR logic within each category
              return categorizedIds[category].some((id) =>
                product.specification.some((spec) =>
                  spec.specification_details.includes(id)
                )
              );
            });
          } else if (nonEmptyCategories.length === 1) {
            // console.log("i am in else || part");
  
            // OR logic for the single category
            const category = nonEmptyCategories[0];
            return categorizedIds[category].some((id) =>
              product.specification.some((spec) =>
                spec.specification_details.includes(id)
              )
            );
          }
          return false;
        });
      });
    }

    // console.log("filtered", filtered);

    // Sort filtered products
    sortProducts(sortBy, filtered);
  }, [size, sortBy, products]);

  const handleSizeChange = (value) => {
    console.log("handleSizeChange", value);
    setSize(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
  };
  const handleSortChange = (value) => {
    console.log("handleSortChange", value);
    setSortBy(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
  };

  const sortProducts = (sortKey, productsToSort) => {
    let sorted = [...productsToSort];
    // console.log("sorted==>", sorted);
    switch (sortKey) {
      case "alphabetical-a-z":
        sorted.sort((a, b) =>
          a.product_name
            .replace(/[^a-zA-Z]/g, "")
            .localeCompare(b.product_name.replace(/[^a-zA-Z]/g, ""))
        );
        // sorted.sort((a, b) => a.product_name.localeCompare(b.product_name));
        break;
      case "alphabetical-z-a":
        sorted.sort((a, b) =>
          b.product_name
            .replace(/[^a-zA-Z]/g, "")
            .localeCompare(a.product_name.replace(/[^a-zA-Z]/g, ""))
        );
        // sorted.sort((a, b) => b.product_name.localeCompare(a.product_name));
        break;
      case "price-low-to-high":
        sorted.sort((a, b) => {
          const aPrice =
            a.prospecdocs?.[0]?.sp +
            (a.prospecdocs?.[0]?.sp * a.taxdocs?.[0]?.tax_percentage) / 100;
          const bPrice =
            b.prospecdocs?.[0]?.sp +
            (b.prospecdocs?.[0]?.sp * b.taxdocs?.[0]?.tax_percentage) / 100;
          return aPrice - bPrice;
        });
        break;
      case "price-high-to-low":
        sorted.sort((a, b) => {
          const aPrice =
            a.prospecdocs?.[0]?.sp +
            (a.prospecdocs?.[0]?.sp * a.taxdocs?.[0]?.tax_percentage) / 100;
          const bPrice =
            b.prospecdocs?.[0]?.sp +
            (b.prospecdocs?.[0]?.sp * b.taxdocs?.[0]?.tax_percentage) / 100;
          return bPrice - aPrice;
        });
        break;
      case "date-new-to-old":
        sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "date-old-to-new":
        sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        break;
    }
    setFilteredProducts(sorted);
    setResultNumbers(sorted?.length);
  };

  // const [menu, setMenu] = useState(category);

  //states for product filter
  const [isPaginationAvailable, setIsPaginationAvailable] = useState(false);
  const [resultNumbers, setResultNumbers] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [priceRange, setPriceRange] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); //holding current page number for pagination
  const [perPage, setPerPage] = useState(10); //holding how much data should be available in single page
  const [categoryName, setcategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [minMax, setMinMax] = useState([0, price_Range?.max_price]);
  const prevPriceRange = useRef(priceRange);

  const [categorys, setCategory] = useState([]);

  const [finalOne, setFinalOne] = useState([]);

  const [currentPage1, setCurrentPage1] = useState(1); // State to track the current page
  const [pageSize1, setPageSize1] = useState(10); // State to track the number of products per page

  const handlePageChange1 = (page, pageSize) => {
    setCurrentPage1(page);
    setPageSize1(pageSize);
  };

  // Calculate the products to display for the current page
  const paginatedProducts = filteredProducts?.slice(
    (currentPage1 - 1) * pageSize1,
    currentPage1 * pageSize1
  );

  //to check if any filter applied
  const anyFilterApplied =
    categoryId !== "" ||
    sortOption !== "" ||
    priceRange.length > 0 ||
    categoryName !== "" ||
    searchQuery !== "";

  // console.log("anyFilterApplied", anyFilterApplied);

  //clear all filters
  const clearAllFilters = () => {
    const currentPathname = window.location.pathname.toLowerCase();
    const currentSearch = window.location.search.toLowerCase();

    // console.log("currentPathname", currentPathname);
    // console.log("currentSearch", currentSearch);

    setCategoryId("");
    handlePriceFilter("");
    setPriceRange([]); // Reset to the initial price range
    setSearchQuery("");
    setSize(""); // Reset the size filter
    setSortOption(""); // Reset sort option

    if (currentSearch) {
      // console.log("/products-->");
      navigate("/products");
      setIsPaginationAvailable(false);
      setCurrentPage1(1);
      setCategoryId("");
      setSortOption("");
      setPriceRange([]);
      setSearchQuery("");
      setcategoryName("");
      setSize(null);
      // setSortBy("Sort by position");
      setSortBy(null);
    } else {
      // console.log("currentSearch-->");
      setCategoryId("");
      setSortOption("");
      setPriceRange([]);
      setSearchQuery("");
      // setcategoryName("")
      setSize(null);
      // setSortBy("Sort by position");
      setSortBy(null);
      setIsPaginationAvailable(false);
      setCurrentPage1(1);
      navigate("/products");
    }

    // setCategoryId("");
    // setSortOption("");
    // setPriceRange([]);
    // setSearchQuery("");
  };

  // console.log("minMax", minMax)

  const getPriceRange = () => {
    api.getPriceRange().then((res) => {
      // console.log("price range", res)
      price_Range = res?.data?.data[0];
      // console.log("priceRange", priceRange)
      setMinMax([price_Range?.min_price, price_Range?.max_price]);
      setIsLoading(false);
    });
  };

  const getAllCategory = async(category) => {
    await api.getcategory().then((res) => {
      if(res?.data?.success === true){
        const data = res?.data?.data?.filter((item) => item?.status == true);
        setCategory(data);
        categoryTree(data);
      }
    })
  }

  const categoryTree = (category) => {
    let filter = category.filter((e) => {
      return e.category_id == null;
    });
    // console.log("filter", filter);
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // console.log("final", final);
    setFinalOne(final);
    return final;
  };
  //subcategory tree
  const categoryTree2 = (id, category) => {
    let filter = category.filter((e) => {
      return e.category_id == id;
    });
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // setFinalOne(final);
    return final;
  };

  useEffect(() => {
    getPriceRange();
  }, []);
  useEffect(() => {
    getPriceRange();
    // console.log("priceRange")
    getAllCategory();
    setIsLoading(true);
  }, [catName]);

  const itemNav = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    // {
    //   href: "/products",
    //   title: "Shop",
    // },
  ];

  const itemNav1 = [
    {
      href: "/products",
      title: "Clear all",
    },
  ];

  //to calculate the total page number
  const totalPageCount = Math.ceil(totalProducts / perPage);

  //get params value if someone try to access the product using catogery name
  const setPrams = () => {
    if (catName) {
      setcategoryName(catName);
    } else if (catId) {
      setCategoryId(catId);
    }
  };

  //when the filter apllies it takes the filter value in the params and get the products from api
  const getProducts = useCallback(async () => {
    const isPriceRangeChanged = prevPriceRange.current[0] !== priceRange[0] || prevPriceRange.current[1] !== priceRange[1];

    if (isPaginationAvailable === true) {
      setCurrentPage(1);
      const data = await api.getAll(
        dispatch,
        [productGetAllStart, productGetAllSuccess, productGetAllFail],
        "products",
        {
          category: categoryId,
          page: 1,
          perpage: 200000000000,
          sortfilter: sortOption,
          min: priceRange[0],
          max: priceRange[1],
          search: searchQuery || product_name,
          brandname: "",
          fields: "",
          categoryname: categoryName,
          newsort: sortBy,
          Size: size,
        },
        (err, res) => {}
      );
    } else {
      const data = await api.getAll(
        dispatch,
        [productGetAllStart, productGetAllSuccess, productGetAllFail],
        "products",
        {
          category: categoryId,
          page: currentPage,
          perpage: perPage,
          sortfilter: sortOption,
          min: priceRange[0],
          max: priceRange[1],
          search: searchQuery || product_name,
          brandname: "",
          fields: "",
          categoryname: categoryName,
          newsort: sortBy,
          Size: sizeOptions,
        },
        (err, res) => {}
      );
    }
    getPriceRange();
    // Only scroll if priceRange hasn't changed
    if (!isPriceRangeChanged) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    prevPriceRange.current = priceRange;
  }, [
    categoryId,
    currentPage,
    perPage,
    size,
    sortBy,
    priceRange,
    searchQuery,
    categoryName,
    product_name,
  ]);

  // console.log("price", minMax)
  useEffect(() => {
    getProducts();
    // set
  }, [getProducts]);

  useEffect(() => {
    setPrams();
  }, [catName, catId]);

  useEffect(() => {
    const amounts = paginatedProducts
      .map((product) => {
        const sp = Number(product?.prospecdocs[0]?.sp);
        const taxPercentage = Number(product?.taxdocs[0]?.tax_percentage);
        const total = sp + Number(((sp * taxPercentage) / 100).toFixed(2));
        // console.log("total-->", total);
  
        // Return total or NaN if either `sp` or `taxPercentage` is invalid
        return isNaN(total) ? null : total;
      })
      .filter((value) => value !== null); // Filter out NaN or invalid values
  
    const largestAmount = Math.max(...amounts);
    const smallestAmount = Math.min(...amounts);

    // setMinMax([smallestAmount, largestAmount]);
  
    // console.log('Largest Amount:', largestAmount);
    // console.log('Smallest Amount:', smallestAmount);
  }, [paginatedProducts]);
  // console.log("size==>", size);
  // console.log("sortBy==>", sortBy);
  // console.log("totalProducts==>", totalProducts);
  // console.log("resultNumbers==>", resultNumbers);

  //function handle price filter
  const handlePriceFilter = (value) => {
    setPriceRange(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
  };

  //handle sort filter
  const handleSortProduct = (option) => {
    setSortOption(option);
    getProducts();
  };

  //function handle pagination
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  const handleViewChange = (value) => {
    // console.log("value", value);
    setPageView(value);
  };

  return (
    <ShopSection>
      {isLoading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
        >
          <div className="wrapper" style={{ padding: "60px 40px" }}>
            <div className="shop_section">
              <div className="shop_head_bar_align">
                <div className="shop_head_bar_left">
                  <h1>Products</h1>
                </div>
                <div className="shop_head_bar_right">
                  <Breadcrumb items={itemNav} />
                </div>
              </div>
              <div className="shop_filter_align">
                {/* <div className="shop_filter_left">
                <Input
                  placeholder="Search Products..."
                  allowClear
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <Divider />
                <h2>Category</h2>
                <Menu
                  mode="inline"
                  items={category}
                  selectedKeys={[categoryId]}
                  onSelect={(e) => {
                    setCategoryId(e?.key);
                  }}
                />
                <Divider />
                <h2>Filter by Price</h2>
                <Slider
                  range={{
                    draggableTrack: true,
                  }}
                  onChange={handlePriceFilter}
                />
                <Divider />
              </div> */}
                <div className="shop_filter_right">
                  <div className="shop_filter_right_top">
                    <Input
                      placeholder="Search Products..."
                      allowClear
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      style={{
                        width: "180px",
                      }}
                    />
                    <Select
                      allowClear
                      label="Category"
                      placeholder="Select Category"
                      mode="inline"
                      value={categoryId ? categoryId : "--Categories--"}
                      onChange={(value) => {
                        // console.log("value", value);
                        setCategoryId(value);
                      }}
                      style={{
                        width: "140px",
                      }}
                      // defaultValue={categoryId ? categoryId : "--Select--"}
                    >
                      {category?.map((item) => (
                        <Option key={item?.key} value={item?.key}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 10 }}>Price: </span>
                      <Slider
                        min={minMax[0]}
                        max={minMax[1]}
                        range={{
                          draggableTrack: true,
                        }}
                        onChange={handlePriceFilter}
                        style={{
                          width: "180px",
                        }}
                        defaultValue={minMax}
                      />
                    </div>
                    <div className="shop_filter_right_top_left">
                      <p className="shop_filter_right_top_left_totalProducts">
                        Showing of1 {totalProducts} results
                      </p>
                      <p
                        className="shop_filter_right_top_left_clear"
                        onClick={clearAllFilters}
                      >
                        {anyFilterApplied ? "Clear all" : ""}
                      </p>
                    </div>
                    {/* <Row>
                    <Col span={24}>
                      Sort : &nbsp;
                      <Select
                        onChange={handleSortProduct}
                        placeholder="Select to Sort "
                        value={sortOption}
                      >
                        <Option key="0" value="">
                          Default Sort
                        </Option>
                        <Option key="1" value="hightolow">
                          High to Low
                        </Option>
                        <Option key="2" value="lowtohigh">
                          Low to High
                        </Option>
                        <Option key="3" value="newtoold ">
                          New to Old
                        </Option>
                        <Option key="4" value="oldtonew">
                          Old to New
                        </Option>
                      </Select>
                    </Col>
                  </Row> */}
                    {/* <Segmented
                    options={[
                      {
                        value: "List",
                        icon: <BarsOutlined />,
                      },
                      {
                        value: "grid",
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                    onChange={(value) => handleViewChange(value)}
                  /> */}
                  </div>
                  <br />
                  <br />
                  {products?.length > 0 && (
                    <div className="product_align_filter">
                      {products?.length > 0 &&
                        products?.map((product) => (
                          <Link
                            to={`/product/${product?._id}`}
                            key={product?._id}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="product_align_filter_box">
                              <div className="p_image">
                                <img
                                  src={
                                    product?.images &&
                                    base_url + product?.images
                                  }
                                  alt="Default"
                                />
                              </div>
                              <div className="p_content">
                                <h2 style={{ color: "black" }}>
                                  {product?.product_name}
                                </h2>
                                <div className="price">
                                  <div className="sp">
                                    {product?.prospecdocs &&
                                    product?.prospecdocs?.length > 0
                                      ? styles?.currency +
                                        (Number(product?.prospecdocs[0]?.sp) +
                                          Number(
                                            (product?.prospecdocs[0]?.sp *
                                              product?.taxdocs[0]
                                                ?.tax_percentage) /
                                              100
                                          ))
                                      : 0}
                                  </div>
                                  <div className="mrp">
                                    {product?.prospecdocs &&
                                    product?.prospecdocs?.length > 0
                                      ? styles?.currency +
                                        (product?.prospecdocs[0]?.mrp +
                                          Number(
                                            (product?.prospecdocs[0]?.sp *
                                              product?.taxdocs[0]
                                                ?.tax_percentage) /
                                              100
                                          ))
                                      : 0}
                                  </div>
                                </div>
                                <div>
                                  <Button size="medium">View Detail</Button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  )}
                  {products?.length == 0 && <Empty />}
                  <div className="shop_pagination">
                    <Pagination
                      Current={currentPage}
                      size="small"
                      onChange={handlePaginationChange}
                      pageSizeOptions={[10, 20, 30]}
                      showSizeChanger
                      showLessItems={totalPageCount <= 2}
                      total={totalProducts}
                    />
                  </div>

                  <div className="shop_filter_right_top_left">
                    <p className="shop_filter_right_top_left_totalProducts">
                      Showing of {totalProducts} results
                    </p>
                    <p
                      className="shop_filter_right_top_left_clear"
                      onClick={clearAllFilters}
                    >
                      {anyFilterApplied ? "Clear all" : ""}
                    </p>
                  </div>

                  {/* <Row>
                  <Col span={24}>
                    Sort : &nbsp;
                    <Select
                      onChange={handleSortProduct}
                      placeholder="Select to Sort "
                      value={sortOption}
                    >
                      <Option key="0" value="">
                        Default Sort
                      </Option>
                      <Option key="1" value="hightolow">
                        High to Low
                      </Option>
                      <Option key="2" value="lowtohigh">
                        Low to High
                      </Option>
                      <Option key="3" value="newtoold ">
                        New to Old
                      </Option>
                      <Option key="4" value="oldtonew">
                        Old to New
                      </Option>
                    </Select>
                  </Col>
                </Row> */}
                  {/* <Segmented
                  options={[
                    {
                      value: "List",
                      icon: <BarsOutlined />,
                    },
                    {
                      value: "grid",
                      icon: <AppstoreOutlined />,
                    },
                  ]}
                  onChange={(value) => handleViewChange(value)}
                /> */}
                </div>
                <br />
                <br />
                {products?.length > 0 && (
                  <div className="product_align_filter">
                    {products?.length > 0 &&
                      products?.map((product) => (
                        <Link
                          to={`/product/${product?._id}`}
                          key={product?._id}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="product_align_filter_box">
                            <div className="p_image">
                              <img
                                src={
                                  product?.images && base_url + product?.images
                                }
                                alt="Default"
                              />
                            </div>
                            <div className="p_content">
                              <h2 style={{ color: "black" }}>
                                {product?.product_name}
                              </h2>
                              <div className="price">
                                <div className="sp">
                                  {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                    ? styles?.currency +
                                      (Number(product?.prospecdocs[0]?.sp) +
                                        Number(
                                          (product?.prospecdocs[0]?.sp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                            100
                                        ))
                                    : 0}
                                </div>
                                <div className="mrp">
                                  {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                    ? styles?.currency +
                                      (product?.prospecdocs[0]?.mrp +
                                        Number(
                                          (product?.prospecdocs[0]?.mrp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                            100
                                        ))
                                    : 0}
                                </div>
                              </div>
                              <div>
                                <Button size="medium">View Detail</Button>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                )}
                {products?.length == 0 && <Empty />}

                <div className="shop_pagination">
                  <Pagination
                    Current={currentPage}
                    size="small"
                    onChange={handlePaginationChange}
                    pageSizeOptions={[10, 20, 30]}
                    showSizeChanger
                    showLessItems={totalPageCount <= 2}
                    total={totalProducts}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      ) : (
        <div className="wrapper" style={{ padding: "60px 40px" }}>
          <div className="shop_section">
            <div className="shop_head_bar_align">
              <div className="shop_head_bar_left">
                <h1>Products</h1>
              </div>
              <div className="shop_head_bar_right">
                <Breadcrumb items={itemNav} />
              </div>
            </div>
            <div className="shop_filter_align">
              {/* <div className="shop_filter_left">
            <Input
              placeholder="Search Products..."
              allowClear
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            <Divider />
            <h2>Category</h2>
            <Menu
              mode="inline"
              items={category}
              selectedKeys={[categoryId]}
              onSelect={(e) => {
                setCategoryId(e?.key);
              }}
            />
            <Divider />
            <h2>Filter by Price</h2>
            <Slider
              range={{
                draggableTrack: true,
              }}               
              onChange={handlePriceFilter}                
            />
            <Divider />              
          </div> */}

              <div className="shop_filter_right">
                <div className="shop_filter_right_top">
                  <Input
                    placeholder="Search Products..."
                    className="shop_filter_right_top_input"
                    allowClear
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setIsPaginationAvailable(true);
                      setCurrentPage1(1);
                    }}
                    style={{
                      width: "250px",
                    }}
                  />
                  {/* {console.log("categoryId==>", categoryId)} */}
                  {
                    company_code !== "69488701-2976-4c9e-b1a3-e864db691e76" &&
                    <Select
                    allowClear
                    className="shop_filter_right_top_left_category"
                    label="Category"
                    placeholder="Select Category"
                    mode="inline"
                    value={categoryId ? categoryId : "Categories"}
                    onChange={(value) => {
                      console.log("value", value);
                      setCategoryId(value);
                      setIsPaginationAvailable(true);
                      setCurrentPage1(1);
                    }}
                    style={{
                      width: "170px",
                    }}
                    defaultValue={categoryId ? categoryId : "--Select--"}
                  >
                    {category?.map((item) => (
                      <Option key={item?.key} value={item?.key}>
                        {item?.label}
                      </Option>
                    ))}
                  </Select>}

                  {
                    company_code == "69488701-2976-4c9e-b1a3-e864db691e76" &&
                    <Cascader
                    allowClear
                    className="shop_filter_right_top_left_category"
                    label="Category"
                      onChange={(value) => {
                    if (value && value.length > 0) {
                     const lastValue = value[value.length - 1];
                      setCategoryId(lastValue);
                      setIsPaginationAvailable(true);
                          setCurrentPage1(1);
                       }
                      }}
                       options={finalOne ? finalOne : `Please select category`}
                     placeholder="Please select category"
                      />
                  }

                  {/* {console.log("finalOne", finalOne)} */}

                  {/* <Cascader
                allowClear
                className="shop_filter_right_top_left_category"
                label="Category"
                  onChange={(value) => {
                if (value && value.length > 0) {
                 const lastValue = value[value.length - 1];
                  setCategoryId(lastValue);
                  setIsPaginationAvailable(true);
                      setCurrentPage1(1);
                   }
                  }}
                   options={finalOne ? finalOne : `Please select category`}
                 placeholder="Please select category"
                  /> */}

                  {/* Size Select */}
                  <Select
                    allowClear
                    className="shop_filter_right_top_left_category"
                    label="Specification"
                    placeholder="Select Spec"
                    mode="multiple"
                    // value={size}
                    // value={size ? size : "Select Size"}
                    onChange={handleSizeChange}
                    style={{ width: "170px" }}
                    options={Object.keys(SpecificationArray).map((specKey) => ({
                      label: <span>{specKey}</span>, // The name of the specification (e.g., SIZE)
                      title: specKey, // Optional: you can use this for the title
                      options: SpecificationArray[specKey].map((spec) => ({
                        label: <span>{spec.label}</span>, // The individual label (e.g., 5XL, S, M)
                        value: spec.value, // The corresponding value (e.g., '65f0584833a4fda24a82cd62')
                      })),
                    }))}
                  />
                    {/* {sizeOptions.map((item) => (
                      <Option key={item.key} value={item.key}>
                        {item.label}
                      </Option>
                    ))}
                  </Select> */}

                  {/* Sorting Selection Dropdown */}
                  <Select
                    allowClear
                    // defaultValue="sort-by-position"
                    value={sortBy ? sortBy : "Sort by position"}
                    onChange={handleSortChange}
                    style={{ width: "170px" }}
                  >
                    {sortOptions.map((option) => (
                      <Option key={option.key} value={option.key}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>

                  <div
                    className="shop_filter_right_top_left_price"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span style={{ marginRight: 10 }}>Price: </span>
                    <Slider
                      min={minMax[0]}
                      max={minMax[1]}
                      range={{
                        draggableTrack: true,
                      }}
                      onChange={handlePriceFilter}
                      style={{
                        width: "180px",
                      }}
                      defaultValue={minMax}
                    />
                  </div>

                  <div className="shop_filter_right_top_left">
                    {size != null && size != undefined ? (
                      <p className="shop_filter_right_top_left_totalProducts">
                        Showing of {resultNumbers} results
                      </p>
                    ) : (
                      <p className="shop_filter_right_top_left_totalProducts">
                        Showing of {totalProducts} results
                      </p>
                    )}
                    <p
                      className="shop_filter_right_top_left_clear"
                      onClick={clearAllFilters}
                    >
                      {anyFilterApplied ? "Clear all" : ""}
                    </p>
                  </div>

                  {/* <Row>
                <Col span={24}>
                  Sort : &nbsp;
                  <Select
                    onChange={handleSortProduct}
                    placeholder="Select to Sort "
                    value={sortOption}
                  >
                    <Option key="0" value="">
                      Default Sort
                    </Option>
                    <Option key="1" value="hightolow">
                      High to Low
                    </Option>
                    <Option key="2" value="lowtohigh">
                      Low to High
                    </Option>
                    <Option key="3" value="newtoold ">
                      New to Old
                    </Option>
                    <Option key="4" value="oldtonew">
                      Old to New
                    </Option>
                  </Select>
                </Col>
              </Row> */}
                  {/* <Segmented
                options={[
                  {
                    value: "List",
                    icon: <BarsOutlined />,
                  },
                  {
                    value: "grid",
                    icon: <AppstoreOutlined />,
                  },
                ]}
                onChange={(value) => handleViewChange(value)}
              /> */}
                </div>
                <br />
                <br />
                {filteredProducts?.length > 0 ? (
                  <div className="product_align_filter">
                    {paginatedProducts.map((product) => (
                      <Link
                        to={`/product/${product?._id}`}
                        key={product?._id}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="product_align_filter_box">
                          <div className="p_image">
                            <img
                              src={
                                product?.images && base_url + product?.images
                              }
                              alt="Default"
                            />
                          </div>
                          <div className="p_content">
                            <h2 style={{ color: "black" }}>
                              {product?.product_name}
                            </h2>
                            <div className="price">
                              <div className="sp">
                                {product?.prospecdocs &&
                                product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                    (Number(product?.prospecdocs[0]?.sp) +
                                      Number(
                                        (
                                          (product?.prospecdocs[0]?.sp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                          100
                                        ).toFixed(2)
                                      ))
                                  : 0}
                              </div>
                              <div className="mrp">
                                {product?.prospecdocs &&
                                product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                    (product?.prospecdocs[0]?.mrp +
                                      Number(
                                        (
                                          (product?.prospecdocs[0]?.mrp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                          100
                                        ).toFixed(2)
                                      ))
                                  : 0}
                              </div>
                            </div>
                            <div>
                              <Button size="medium">View Detail</Button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <Empty />
                )}

                <div className="shop_pagination">
                  {isPaginationAvailable == false && (
                    <Pagination
                      Current={currentPage}
                      size="small"
                      onChange={handlePaginationChange}
                      pageSizeOptions={[10, 20, 30]}
                      showSizeChanger
                      showLessItems={totalPageCount <= 2}
                      total={totalProducts}
                    />
                  )}
                  {/* Add Pagination component */}
                  {/* {console.log("currentPage1 ==>", currentPage1)} */}
                  {/* {console.log("pageSize1 ==>", pageSize1)} */}
                  {isPaginationAvailable == true && (
                    <Pagination
                      current={currentPage1}
                      pageSize={pageSize1}
                      total={filteredProducts.length}
                      onChange={handlePageChange1}
                      showSizeChanger
                      pageSizeOptions={[10, 20, 50]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ShopSection>
  );
};

export default Shop;

const ShopSection = styled.section`
  /* margin: ${({ company_code }) => (company_code == '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg' ? '100px 0' : '0px 0')}; */
  margin: 60px 0;
  width: 100%;
  display: inline-block;
  position: relative;
  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding: 0;
    padding-left: 0 !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
  .ant-menu-light
    > .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-submenu-title:active {
    background-color: transparent;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0px;
  }
  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }
  .ant-menu .ant-menu-submenu,
  .ant-menu .ant-menu-submenu-inline {
    background: transparent;
  }
  .shop_head_bar_left {
  }

  .shop_section {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .shop_head_bar_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px;
  }
  .shop_filter_align {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .shop_filter_left {
    width: 21%;
    display: inline-block;
    border: 1px solid #f5f5f5;
    border-radius: 7px;
    padding: 25px;
  }
  .shop_filter_right {
    width: 100%;
    display: inline-block;
  }
  .shop_filter_left h2 {
    font-size: 19px;
    margin: 0 0 25px;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 10px;
  }
  .ant-divider {
    margin: 35px 0;
  }
  .shop_filter_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .shop_filter_right_top_left {
      display: flex;
      .shop_filter_right_top_left_totalProducts {
        width: 180px;
        margin-bottom: 0px;
      }
      .shop_filter_right_top_left_clear {
        cursor: pointer;
        color: #ff0000;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }

  /* .product_align_filter .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 25px 0 50px 0;
  } */

  .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 25px 0 50px 0;
    justify-content: center;
  }

  .product_align_filter_List {
    width: 100%;
    gap: 20px;
  }

  .product_align_filter_box_List {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .p_image_List img {
    width: 35%;
    height: auto;
    border-radius: 4px;
  }

  .p_content_List {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    list-style-type: none;
  }

  .price_List {
    display: flex;
    gap: 20px;
    line-height: 60px;
    margin-top: 8px;
  }

  .price_List .sp {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #000;
    font-family: ${font?.bold};
  }

  .price_List .sp {
    color: #000;
    font-size: 18px;
    text-decoration: none;
  }

  .price_List .mrp {
    text-decoration: line-through;
    color: #c9c7c7;
  }

  .product_align_filter_box {
    width: 99%;
    height: 470px;
    border-radius: 7px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
  }
  .p_image {
    width: 100%;
    height: 70%;
    display: inline-block;
  }
  .p_image img {
    width: 100%;
    height: 100%;
  }
  .p_content {
    padding: 15px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-decoration: none;
    height: 30%;
  }
  .p_content h2 {
    font-size: 17px;
    font-family: ${font?.bold};
    color: ${font?.color};
    text-decoration: none;
    height: 25%;
  }
  .price {
    display: flex;
    align-items: center;
    gap: 8px 11px;
  }
  .price div:nth-child(1) {
    color: ${theme?.color};
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
  .price div:nth-child(2) {
    font-size: 14px;
    color: ${theme?.gray};
    text-decoration: line-through;
  }
  .p_content button {
    padding: 2px 9px;
    height: auto;
    font-size: 14px;
  }
  .p_content button * {
    font-size: 14px;
  }
  .shop_pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    .product_align_filter {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 768px) {
    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    .shop_filter_align {
      flex-direction: column;
      gap: 20px;
    }

    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    .shop_filter_right_top_left_price,
    .shop_filter_right_top_input {
      width: 150px;
    }

    .shop_filter_right_top_left {
      width: 180px;
    }

    .shop_filter_left,
    .shop_filter_right {
      width: 100%;
    }

    .product_align_filter {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 431px) {
    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }


  @media screen and (max-width: 425px) {
    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }

  @media screen and (max-width: 375px) {
    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }

  @media screen and (max-width: 320px) {
    .shop_filter_right_top {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 80% !important;
    }
    .shop_filter_right_top_left_price {
      width: 80% !important;
    }
    .shop_filter_right_top_left_category {
      width: 80% !important;
    }
    .shop_filter_right_top_left {
      width: 250px;
    }
  }
`;

const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
  .Forgot_Password {
    width: 100%;
    text-align: right;
    margin: 0;
    Link {
      color: #000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
