import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider } from "antd";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const TC11 = ({ category, content_all }) => {
  // console.log("categoryTC11", content_all);
  const api = new API();

  const [slidesPerView, setSlidesPerView] = useState(4);
  const [spaceBetween, setSpaceBetween] = useState(10);

  const updateSlidesPerView = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1200) {
      setSlidesPerView(6);
      setSpaceBetween(10);
    } else if (windowWidth >= 1024) {
      setSlidesPerView(6);
      setSpaceBetween(0);
    } else if (windowWidth >= 992) {
      setSlidesPerView(5);
      setSpaceBetween(-100);
    } else if (windowWidth >= 912) {
      setSlidesPerView(5);
      setSpaceBetween(-100);
    } else if (windowWidth >= 768) {
      setSlidesPerView(6);
      setSpaceBetween(0);
    } else if (windowWidth >= 540) {
      setSlidesPerView(5);
      setSpaceBetween(0);
     
    }  else if (windowWidth >= 480) {
      setSlidesPerView(4);
      setSpaceBetween(0);
    } 
    else if (windowWidth >= 320) {
      setSlidesPerView(3);
      setSpaceBetween(0);
    }
     else {
      setSlidesPerView(5);
      setSpaceBetween(0);
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);

    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  return (
    <CategoryAlign>
      <div className="home_wrapper">
        {/* <Divider /> */}

        {/* <TopCategorySection> */}
          <div
            className="TopCategory_Header"
            style={{ width: "100%", marginBottom: "50px" }}
          >
            <h2 style={{ paddingLeft: "25px", paddingRight: "75px" }}>
              {/* CATEGORIES */}
              {category?.section_title}
            </h2>
          </div>
          <div className="TopCategory_Main">
          <Swiper
          style={{width:"100%"}}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          >
            {category?.category_id?.map((category, index) => {
              return (
              <SwiperSlide key={`TC11_${index}`}>
              <Link
                key={index}
                to={`/Products?catName=${category?.name
                  ?.replace(" ", "-")
                  ?.toLowerCase()
                  ?.replace(/ /g, "")
                  ?.replace(/[^\w-]+g/, "")}`}
                style={{ textDecoration: "none" }}
              >
                <div className="top_category">
                  <CategoryImageWrapper
                    style={{
                      // background: generateRandomShade(getColorByIndex(index)),
                      background: "#F2F1EB",
                      borderRadius: "50%",
                    }}
                  >
                                        <Tooltip title={category?.name}>

                    <img
                      src={
                        category?.image
                          ? api.rootUrl1 + category?.image
                          : Default
                      }
                      alt={`Category ${index + 1}`}
                    />
                                        </Tooltip>
                  </CategoryImageWrapper>
                  <CategoryName>
                  <Tooltip title={category?.name}>
                      <p>{category?.name}</p>
                      </Tooltip>
                  </CategoryName>
                </div>
              </Link>
              </SwiperSlide>
            )}
            )}
             </Swiper>
          </div>
        {/* </TopCategorySection> */}
        {/* <Divider /> */}
      </div>
    </CategoryAlign>
  );
};

export default TC11;

const CategoryAlign = styled.div`
margin-top: 60px;
  .home_wrapper {
    padding: 0 40px;
  }

  .top_category {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid red; */
    text-align: center;
    gap: 10px;
  }
`;

const TopCategorySection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  .TopCategory_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
  }

  Link,
  a {
    text-decoration: none;
  }

  .top_category {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border: 1px solid red;
    text-align: center;
    gap: 10px;
  }

  /* @media screen and (min-width: 1200px) and (max-width: 1500px) {
    .top_category{
      width: 200px;
    }
  }
  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    .top_category{
      width: 180px;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    .top_category{
      width: 160px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .top_category{
      width: 140px;
    }
  }

  @media screen and (min-width: 660px) and (max-width: 768px) {
    .top_category{
      width: 120px;
    }
  }

  @media screen and (min-width: 520px) and (max-width: 660px) {
    .top_category{
      width: 100px;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 520px) {
    .top_category{
      width: 80px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 360px) {
    .top_category{
      width: 60px;
    }
  } */
  /* @media screen and (min-width: 320px) and (max-width: 1100px) {
  
  .TopCategory_Main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
 
}
} */

  /* @media screen and (min-width: 520px) and (max-width: 768px) {
    .TopCategory_Main {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 519px) {
    .TopCategory_Main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  } */
`;

const CategoryImageWrapper = styled.div`
  height: 150px;
  width: 150px;
  padding: 25px;
  border-radius: 50%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  Link {
    text-decoration: none;
  }
`;

const CategoryName = styled.div`
  /* max-width: 10ch; */
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 20px;
  text-align: center;

  Link {
    text-align: center;
  }

  p{
    /* border: 1px solid red; */
  }
`;
