// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'https://mygirlshub.com/api/v1/website/';
// export const api_admin = 'https://mygirlshub.com/api/v1/admin/';
// export const base_url = 'https://mygirlshub.com/';
// export const app_title = 'ecDigi Technologies'; 

// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.130:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.130:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.130:8008/';
// export const app_title = 'ecDigi Technologies';

//Local Poornima//
// export const company_code = '69488701-2976-4c9e-b1a3-e864db691e76';
// export const api_website = 'http://192.168.100.212:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:8008/';
// export const app_title = 'ecDigi Technologies';

//Live Poornima latest//
// export const company_code = '69488701-2976-4c9e-b1a3-e864db691e76';
// export const api_website = 'http://172.232.116.195:8459/api/v1/website/';
// export const api_admin = 'http://172.232.116.195:8459/api/v1/admin/';
// export const base_url = 'http://172.232.116.195:8459/';
// export const app_title = 'ecDigi Technologies';

//Local GirlsHub//
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.212:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:8008/';
// export const app_title = 'ecDigi Technologies';

// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.154:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.154:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.154:8008/';
// export const app_title = 'ecDigi Technologies';

// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'https://mygirlshub.com/api/v1/website/';
// export const api_admin = 'https://mygirlshub.com/api/v1/admin/';
// // export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'https://mygirlshub.com/';
// export const app_title = 'ecDigi Technologies'; 

//Live GirlsHub//
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'https://admin.ecdigi.com/api/v1/website/';
// export const api_admin = 'https://admin.ecdigi.com/api/v1/admin/';
// export const base_url = 'https://admin.ecdigi.com/';
// export const app_title = 'ecDigi Technologies';

//Live GirlsHub http 172//
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://172.232.116.195:8459/api/v1/website/';
// export const api_admin = 'http://172.232.116.195:8459/api/v1/admin/';
// export const base_url = 'http://172.232.116.195:8459/';
// export const app_title = 'ecDigi Technologies';

//Local onewear//
// export const company_code = 'e9237dd0-d58a-426b-b9bb-e89bbee2817a';
// export const api_website = 'http://192.168.100.212:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:8008/';
// export const app_title = 'ecDigi Technologies';

//Live onewear latest//
// export const company_code = 'e9237dd0-d58a-426b-b9bb-e89bbee2817a';
// export const api_website = 'http://172.232.116.195:8459/api/v1/website/';
// export const api_admin = 'http://172.232.116.195:8459/api/v1/admin/';
// export const base_url = 'http://172.232.116.195:8459/';
// export const app_title = 'ecDigi Technologies';

//Local smile//
// export const company_code = '27a39f65-8bc3-4009-8749-417069a7f0ed';
// export const api_website = 'http://192.168.100.212:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:8008/';
// export const app_title = 'ecDigi Technologies';

//Live smile//
// export const company_code = '27a39f65-8bc3-4009-8749-417069a7f0ed';
// export const api_website = 'https://admin.ecdigi.com/api/v1/website/';
// export const api_admin = 'https://admin.ecdigi.com/api/v1/admin/';
// export const base_url = 'https://admin.ecdigi.com/';
// export const app_title = 'ecDigi Technologies';

//Live smile latest//
// export const company_code = '27a39f65-8bc3-4009-8749-417069a7f0ed';
// export const api_website = 'http://172.232.116.195:8459/api/v1/website/';
// export const api_admin = 'http://172.232.116.195:8459/api/v1/admin/';
// export const base_url = 'http://172.232.116.195:8459/';
// export const app_title = 'ecDigi Technologies';

//Local gowri//
// export const company_code = 'c6d06571-0b36-439b-95f3-c51bbfe2d6a';
// export const api_website = 'http://192.168.100.212:8008/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:8008/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:8008/';
// export const app_title = 'ecDigi Technologies';

//Live gowri//
// export const company_code = 'c6d06571-0b36-439b-95f3-c51bbfe2d6a';
// export const api_website = 'https://admin.ecdigi.com/api/v1/website/';
// export const api_admin = 'https://admin.ecdigi.com/api/v1/admin/';
// export const base_url = 'https://admin.ecdigi.com/';
// export const app_title = 'ecDigi Technologies';

//Live gowri latest//
export const company_code = 'c6d06571-0b36-439b-95f3-c51bbfe2d6a';
export const api_website = 'http://172.232.116.195:8459/api/v1/website/';
export const api_admin = 'http://172.232.116.195:8459/api/v1/admin/';
export const base_url = 'http://172.232.116.195:8459/';
export const app_title = 'ecDigi Technologies';

//Live onewear//
// export const company_code = 'e9237dd0-d58a-426b-b9bb-e89bbee2817a';
// export const api_website = 'http://172.105.38.68:8458/api/v1/website/';
// export const api_admin = 'http://172.105.38.68:8458/api/v1/admin/';
// export const base_url = 'http://172.105.38.68:8458/';
// export const app_title = 'ecDigi Technologies';

// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.163:7000/api/v1/website/';
// export const api_admin = 'http://192.168.100.163:7000/api/v1/admin/';
// export const base_url = 'http://192.168.100.163:7000/';
// export const app_title = 'ecDigi Technologies';

// REACT_APP_API_WEBSITE=https://mygirlshub.com/api/v1/website/
// REACT_APP_API_ADMIN=https://mygirlshub.com/api/v1/admin/
// REACT_APP_BASE=https://mygirlshub.com/
// REACT_APP_TITLE=ecdigi Technologies